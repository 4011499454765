import { useState } from 'react'
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import{ init } from '@emailjs/browser';
init("user_GxtNcVfiIfygmpLR0ZJnl");




const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  
  const [{ name, email, message }, setState] = useState(initialState)

   const notify = () => toast("Joined");

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log("testing email", name, email, message);

    let templateParams = {
      to_name: 'getpublius@gmail.com',
      from_name: name  ,
      message: email
     }
     emailjs.send(
      'service_40o7v12',
      'template_6k23zjj',
       templateParams,
      'user_GxtNcVfiIfygmpLR0ZJnl'
     ).then(res => {
        notify();
     })
    // window.emailjs.send(
    //   'gmail', "template_6k23zjj",
    //   {message_html: message, from_name: name + ", " + email, reply_to: email}
    // ).then(res => {
    //   console.log('Email successfully sent!')
    // })
    // emailjs
    //   .sendForm(
    //     'default_service', 'template_zsq2uy6', e.target, 'user_twn2KuV5Ophl4Y2U37RMJ'
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text)
    //       clearState()
    //     },
    //     (error) => {
    //       console.log(error.text)
    //     }
    //   )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to join the waitlist.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                        style={{borderRadius: "15px"}}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                        style={{borderRadius: "15px"}}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Join
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
              <ToastContainer 
                position="bottom-center"
              />
                {/*<ul>*/}
                  {/*<li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>*/}
                {/*</ul>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
